import { mapActions, mapState } from 'pinia'
import { jwtDecode } from 'jwt-decode'

import localStorageKeys from '@/constants/local-storage-keys'
import statuses from '@/constants/statuses'
import contexts from '@/constants/contexts'
import events, { loginWithActionEvent } from '@/constants/events'
import { getGravatarByEmail, AuthPages, authPathsUtil, getAuthPaths } from '@/utils/auth/auth'

export const AuthFeedbackMethods = {
  verify: 'VERIFY',
  login: 'LOGIN',
  resetPassword: 'RESET_PASSWORD'
}
export const createAuthFeedback = (message, code = 'unknown', type = 'info', action = null, actionText = null) => ({
  code,
  message,
  action,
  actionText,
  type,
})

/**
 * Used only to authenticate
 * Will import huge packages onces created
 * For access to logged in user info: use user state
 */
export default {
  middleware: 'auth',
  auth: false,

  emits: [events.userIsInitiated],

  data () {
    return {
      cognitoFeedback: null,
      tokenGlue: 'Bearer ',
      amplifyLoaded: false,
      Auth: null,
      authPaths: null,
      timer: null,
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),

    ...mapState(useLocaleStore, ['isCharter', 'localeURLs']),
    ...mapState(useUserStore, {
      noFavourites: 'noFavourites',
      getToken: 'getTokenForRequest',
      isInitiated: 'isInitiated',
      isLoggedIn: 'isLoggedIn',
      $user: 'user'
    }),
  },

  async created () {
    this.authPaths = getAuthPaths(this.locale)

    if (process.browser) {
      if (!this.amplifyLoaded) {
        await this.initAmplify()
        this.amplifyLoaded = true
      }

      this.$event.$on(events.authLoggedInSuccess, this.didLogIn)
    }
  },

  beforeUnmount () {
    this.$event.$off(events.authLoggedInSuccess, this.didLogIn)
    clearTimeout(this.timer)
  },

  methods: {
    ...mapActions(useUserStore, {
      loginActionToggleFavourites: 'toggleFavourite',
      initUser: 'init',
      clearUserForLogout: 'clearUserForLogout',
      setUser: 'setUser',
      setToken: 'setToken',
      setFeedback: 'SET_FEEDBACK',
    }),

    async initAmplify () {
      const { Auth } = await import('@aws-amplify/auth')
      const { Amplify } = await import('@aws-amplify/core')

      let baseUrl = process.browser ? window?.location?.origin : null
      if (!baseUrl) {
        baseUrl = this.localeURLs.BASE_URL
      }

      const config = {
        Auth: {
          userPoolId: 'eu-north-1_qi1hJW3SW',
          identityPoolId: 'eu-north-1:09d4de32-6c94-4c29-af8c-404669d03b28',
          userPoolWebClientId: 'u6dnu9braldto5p65qh9i7mch',
          region: 'eu-north-1',
          mandatorySignIn: false,
          oauth: {
            domain: 'rolfsflygochbuss.auth.eu-north-1.amazoncognito.com',
            scope: ['email', 'profile', 'openid'], // public_profile profile
            redirectSignIn: `${baseUrl}/medlem/callback?login`,
            redirectSignOut: `${baseUrl}/medlem/callback?logout`,
            responseType: 'token' // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
          }
        }
      }

      Amplify.configure(config)
      Auth.configure(config)

      this.Auth = Auth
    },

    _clearState (v) {
      if (v !== undefined && v === true) {
        return
      }
      this.setFeedback(null)
    },

    async _handleNewToken (token, user = {}) {
      if (!token) {
        return
      }
      try {
        const decodedToken = jwtDecode(token)
        if (this.$dayjs(decodedToken?.exp * 1000) < this.$dayjs()) {
          this.setToken(null)
          return false
        }

        if (Object.keys(user).length !== 0) {
          this.setUser({
            ...decodedToken,
            ...user,
            token
          })
        }

        this.initUser({ token })

        await this.cognitoGetUser(undefined, undefined, decodedToken)

        this.didLogIn()
        return true
      } catch (error) {
        this.$sentrySetContext('context', JSON.stringify({
          user, token, error
        }))
        this.$sentryCaptureException('token unhandled', {
          level: 'error',
          tags: {
            type: 'auth',
          }
        })
        return false
      }
    },

    _handleSigninChallenges (user, password, username) {
      if (!user?.challengeName) {
        return user
      }
      switch (user?.challengeName) {
        case 'NEW_PASSWORD_REQUIRED': {
          // TODO: pick required attributes from user
          const name = user?.challengeParam?.userAttributes?.name || username
          return this.Auth.completeNewPassword(
            user,
            password,
            {
              name,
              picture: user?.challengeParam?.userAttributes?.picture || getGravatarByEmail(name)
            },
          ).catch(this.$sentryCaptureException)
        }
        default: {
          this.$sentrySetContext('context', JSON.stringify({
            name: user?.challengeName,
            username
          }))
          this.$sentryCaptureException('unhandled signin challenge', {
            level: 'info',
            tags: {
              type: 'auth',
            }
          })
        }
      }

      return user
    },

    _handleFeedbacks (e, payload = null) {
      let feedback
      switch (e.code) {
        case 'UsernameExistsException': {
          feedback = createAuthFeedback(
            this.$t('memberUsernameExistsException'),
            e.code,
            contexts.info,
            AuthFeedbackMethods.login,
            this.$t('memberFeedbackClickHereToLogIn'),
          )
          break
        }
        case 'UserNotConfirmedException': {
          feedback = createAuthFeedback(
            this.$t('memberUserNotConfirmedException'),
            e.code,
            contexts.info,
            payload?.username ? `${AuthFeedbackMethods.verify}:${payload.username}` : AuthFeedbackMethods.verify,
            this.$t('formResendCode'),
          )
          break
        }
        case 'CodeMismatchException':
        case 'ExpiredCodeException': {
          const method = payload?.context === 'passwordReset'
            ? AuthFeedbackMethods.resetPassword
            : AuthFeedbackMethods.verify
          feedback = createAuthFeedback(
            this.$t('memberExpiredCodeException'),
            e.code,
            'error',
            payload?.username ? `${method}:${payload.username}` : method,
            this.$t('formResendCode'),
          )
          break
        }
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
        case 'LimitExceededException': {
          feedback = createAuthFeedback(this.$t(`member${e.code}`), e.code, contexts.error)
          break
        }
        default: {
          this.$sentrySetContext('context', JSON.stringify({
            code: e.code,
            message: e.message
          }))
          this.$sentryCaptureException('unhandled auth feedback', {
            level: 'info',
            tags: {
              type: 'auth',
            }
          })
          feedback = createAuthFeedback(e.message, e.code, contexts.error)
        }
      }
      if (feedback) {
        this.setFeedback(feedback)
        return {
          status: statuses.feedback,
          payload: feedback
        }
      }
      this.setFeedback(e)
      return {
        status: statuses.feedback,
        payload: e
      }
    },

    async cognitoInitiate () {
      const localToken = window?.localStorage.getItem(localStorageKeys.auth.token)

      // Fired in LoginModal to be just once
      if (this.isLoggedIn) {
        if (!(this.$user?.nickname || this.$user?.name || this.$user?.picture)) {
          if (localToken) {
            await this._handleNewToken(localToken, jwtDecode(localToken))
          } else {
            await this.cognitoGetUser()
          }
          await this.cognitoGetUser()
          await this.initUser()
        }
        if (this.noFavourites && !this.isInitiated) {
          await this.handleLoginAction()
          await this.initUser()

          this.$event.$emit(events.userIsInitiated)
        }
      }
    },

    async cognitoHandleLogin (username, password) {
      await this.awaitInit()
      this._clearState()
      return this.Auth.signIn(username, password)
        .then(async (response) => {
          await this._handleSigninChallenges(response, password, username)

          const token = response.signInUserSession?.idToken?.jwtToken // accessToken?.jwtToken
          const user = response.signInUserSession?.idToken?.payload
          this._handleNewToken(token, user)

          return response
        })
        .then(payload => ({
          status: statuses.ok,
          payload
        }))
        .catch(e => this._handleFeedbacks(e, { username }))
    },

    async cognitoGetUser (credentials = undefined, idToken = undefined, userData = null) {
      if (!idToken) {
        return
      }

      await this.awaitInit()

      return this.Auth.currentAuthenticatedUser(credentials)
        .then((currentUser) => {
          let payload = currentUser.signInUserSession?.idToken?.payload || currentUser

          if (!payload?.name) {
            payload = jwtDecode(idToken)
          }
          if (userData) {
            Object.assign(payload, userData)
          }

          this.setUser(payload)

          return { ...currentUser, payload }
        })
        .catch((error) => {
          this.$sentrySetContext('context', JSON.stringify(error)) // { error, credentials }))
          this.$sentryCaptureException('auth: unhandled getUser', {
            level: 'info',
            tags: {
              type: 'auth',
            }
          })
        })
    },

    async cognitoHandleLogout () {
      await this.awaitInit()

      this._clearState()
      this.clearUserForLogout(null)

      try { this._handleNewToken(null) } catch {}
      try { await this.Auth.signOut() } catch {}
      try { window?.localStorage.setItem(`${this.locale}_userState`, '') } catch {}

      this.clearUserForLogout()
    },

    async cognitoHandleSuccessfulLogout () {
      await this.awaitInit()

      this.setFeedback(createAuthFeedback(
        this.$t('memberSuccessfullyLoggedOut'),
        'successfulLogout',
        contexts.success
      ))
      this.$router.push(this.authLinkTo(AuthPages.login)).catch(() => {})
    },

    async cognitoCheckIfUserIsVerified (username) {
      /**
       * There's a known issue in Cognito where the only way to get the status of a user without logging them in, is ironically to try and log in with a purposefully incorrect password.
       * The error code returned tells us the state of the user.
       * If we get a 'NotAuthorizedException', all's good but the password.
       * If the user isn't confirmed, not found or needs a new password - they are not verified
       * https://github.com/aws-amplify/amplify-js/issues/612
       * https://github.com/aws-amplify/amplify-js/issues/1067
       */
      await this.awaitInit()

      return this.Auth.signIn(username, 'purposefullyIncorrectPassword')
        .then(() => true)
        .catch((err) => {
          switch (err.code) {
            case 'NotAuthorizedException':
              return true
            case 'UserNotFoundException':
            case 'PasswordResetRequiredException':
            case 'UserNotConfirmedException':
            default:
              return false
          }
        })
    },

    async cognitoHandleSignup (username, password) {
      /**
       *  Looking for the email template?
       *  Cognito > User Pool > User Pool Properties > Message Lambda
       */
      await this.awaitInit()

      return this.Auth.signUp({ username, password, attributes: { name: username, picture: getGravatarByEmail(username) } })
        .then(payload => ({
          status: statuses.ok,
          payload
        }))
        .catch(this._handleFeedbacks)
    },

    async cognitoVerifySignup (username, code) {
      await this.awaitInit()

      return new Promise(resolve => this.Auth.confirmSignUp(username, `${code}`.trim())
        .then(payload => resolve({
          status: statuses.ok,
          payload
        }))
        .catch(async (error) => {
          /**
           *  For some unknown reason, we get the odd cases where the users code is correct, the state is verified, but Cognito still throws an 'ExpiredCodeException' error.
           *  To get around that, we check the status of the user. If they are verified, everything is in order.
           *  Hence the double check here, to return a verified user with the correct feedback.
           *  If they aren't confirmed, or the code is actually faulty, those cases are handled here.
           */
          if (error.code === 'ExpiredCodeException') {
            const userIsVerified = await this.cognitoCheckIfUserIsVerified(username)
            if (userIsVerified) {
              resolve({
                status: statuses.ok,
                payload: {
                  username,
                  verified: true
                }
              })
            }
          }

          resolve(this._handleFeedbacks(error, { username, context: 'verify' }))
        }))
    },

    async cognitoResendVerification (username) {
      await this.awaitInit()

      return this.Auth.resendSignUp(username)
        .then(payload => ({
          status: statuses.ok,
          payload
        }))
        .catch(this._handleFeedbacks)
    },

    /**
     * Used when logging in with google
     * @param {string} providerProp provider (ex google)
     * @returns {object} user
     */
    async cognitoFederatedSignIn (providerProp) {
      await this.awaitInit()

      const res = await this.Auth.federatedSignIn({ provider: providerProp })
      return res
    },

    /**
     * Used when logging in with facebook
     * @returns {object} user
     */
    async cognitoFederatedSignInWithOptions (a, b, c) {
      await this.awaitInit()

      return this.Auth.federatedSignIn(a, b, c)
    },

    async cognitoEvaluateHash (hashes) {
      await this.awaitInit()

      const { keys, ...item } = hashes
      const { id_token: idToken } = item

      await this.Auth.federatedSignIn('accounts.google.com', item)
        .then(response => this.cognitoGetUser(response, idToken))
        .then(decoded => this._handleNewToken(idToken, decoded?.payload))
        .catch(this._handleFeedbacks)
    },

    async cognitoForgotPassword (username) {
      await this.awaitInit()

      return this.Auth.forgotPassword(username)
        .then(payload => ({
          status: statuses.ok,
          payload
        }))
        .catch(e => this._handleFeedbacks(e, { username }))
    },

    async cognitoForgotPasswordSubmit (username, code, password) {
      await this.awaitInit()

      return this.Auth.forgotPasswordSubmit(username, `${code}`.trim(), password)
        .then(payload => ({
          status: statuses.ok,
          payload
        }))
        .catch(e => this._handleFeedbacks(e, { username, context: 'passwordReset' }))
    },

    getRedirectRoute () {
      let route = window?.localStorage?.getItem(localStorageKeys.auth.currentRoute)

      if (!route || route.includes(this.localeURLs.auth)) {
        route = this.authLinkTo(AuthPages.default)
      }

      return route
    },

    didLogIn () {
      if (!process.browser) {
        this.$router.push(this.authLinkTo(AuthPages.default)).catch(() => {})
        return
      }

      const route = this.getRedirectRoute()

      this.handleLoginAction()
        .finally(async () => {
          if (!this.amplifyLoaded) {
            await this.initAmplify()
          }

          if (this.$router.currentRoute.value?.path !== route) {
            this.$router.push(route).catch(() => {})
          }
        })
    },

    handleLoginAction () {
      let action
      try {
        action = JSON.parse(localStorage?.getItem(localStorageKeys.auth.action))
      } catch {}

      return new Promise((resolve) => {
        if (action?.action) {
          action = action.action
        }
        if (action && action.name) {
          switch (action.name) {
            case loginWithActionEvent.toggleFavourite:
              this.loginActionToggleFavourites(action.tripTypeId)
                .then(() => {
                  window.localStorage.setItem(localStorageKeys.auth.action, '')
                  resolve()
                })
              break
            default:
              resolve()
          }
        } else {
          resolve()
        }
      })
    },

    authLinkTo (key) {
      return authPathsUtil(key, this.locale, this.localeURLs)
    },

    awaitInit () {
      let i = 0
      let j = 0
      const setTimer = (fn) => {
        this.timer = window.setTimeout(fn, 100)
      }
      const tryToReset = () => {
        i = 0
        j = 1
        this.initAmplify()
      }
      const isLoaded = () => this.amplifyLoaded
      return new Promise((resolve, reject) => {
        const checkIsLoaded = () => {
          if (i > 200) {
            if (!j) {
              tryToReset()
            } else {
              reject(new Error('Could not load Amplify'))
            }
          } else if (!isLoaded()) {
            i++
            setTimer(checkIsLoaded)
          } else {
            resolve()
          }
        }
        checkIsLoaded()
      })
    },

    initiatingSocialLogin () {
      const currentRoute = this.$router.currentRoute.value.fullPath
      localStorage.setItem(localStorageKeys.auth.currentRoute, !currentRoute.includes(this.localeURLs.auth) ? currentRoute : '')
      this.clearUserForLogout()
    },
  }
}
